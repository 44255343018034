@import "../../App.sass"

.order_info_container_div 
    width: 100%

    .order_div_wrapper 
        padding: $standardPadding
        @include flexColumn
        gap: $standardMargin
        position: relative

    .transaction_list_div 
        -webkit-box-shadow: $boxShadow
        box-shadow: $boxShadow
        padding: $standardPadding
        .transaction_list_div_title 
            font-size: $standardFont
            color: $fadeGray




