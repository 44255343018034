@import "../../App.sass"

.login_page
    @include flexColumnCenter
    align-items: center
    padding: $largePadding
    box-shadow: $boxShadow
    width: 400px
    max-width: 100%
    margin: 0 auto
    margin-top: 12rem
    @media screen and (max-width: 500px)
        margin: 0 $standardMargin
        align-self: center
        overflow: hidden
        width: auto
    span
        color: $dangerColor

    h1 
        margin-bottom: $medMargin
        text-align: center

    .username_container, .password_container
        @include flexColumn
        align-items: flex-start
        margin-bottom: $medMargin

        label 
            margin-bottom: $standardMargin

        input
            padding: $smallMargin
            width: 360px
            max-width: 100%
            border: 1px solid $fadeGray
            &::placeholder
                opacity: 0.5 !important
            &:active, &:focus, &:hover
                outline: none
    
    button 
        padding: $medMargin $largeMargin
        background-color: $highlightColor
        border: none
        cursor: pointer
        width: 100%
        &:hover 
            box-shadow: $boxShadow

    .signin_note
        max-width: 500px
        @media screen and (max-width: 500px)
            max-width: 100%
        p
            text-align: justify
            text-justify: inter-word

    @media screen and (max-width: 768px) 
        .profile_pic_container,
        .username_container, .password_container
            max-width: 100%
    
  

