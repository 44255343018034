.table
    padding: 0.25rem
    margin: 0.1rem
    font-size: 14px
    cursor: pointer
    overflow-x: auto

    .table_cell 
        color: inherit

        .cell_wrapper 
            display: flex
            align-items: center
  
.cell_img 
    width: 32px
    height: 32px
    border-radius: 50%
    margin-right: 10px
    object-fit: cover

.status 
    padding: 0.2rem 0.5rem
    font-weight: 700

.delivered 
    background-color: #b5e0c4

.pending 
    background-color: #ffdd9e

.inprocess 
    background-color: #ffafaf

.completed  
    background-color: #aec6cf

.rejected 
    background-color: #ffb6b9
  
@media screen and (max-width: 992px) 
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr 
        font-size: 11.5px !important

@media screen and (max-width: 500px) 
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr 
        font-size: 11px !important