body {
    font-family: Arial, sans-serif;
}
.container {
    width: 100%;
    margin: 0 auto;
}
.invoice-title {
    text-align: center;
    margin-top: 20px;
    line-height: 1;
}
.invoice-title h3 {
    margin: 0;
    padding: 0;
    color: #333;
}
.text-muted {
    color: #6c757d;
    line-height: 1;
}
.mb-1 {
    margin-bottom: 1rem;
}
.mb-4 {
    margin-bottom: 4rem;
}
table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0; /* Add border-spacing to control cell spacing */
    margin-top: 20px;
    page-break-inside: auto;
}
th, td {
    padding: 5px;
    page-break-inside: avoid;
    page-break-after: auto;
    border-right: 1px solid #ddd; /* Add border only for the right side of cells */
    border-left: 1px solid #ddd; /* Add border only for the right side of cells */    
}
.table-nowrap tr:not(:last-child):nth-last-child(2) th,
.table-nowrap tr:not(:last-child):nth-last-child(2) td {
    border-bottom: 1px solid #ddd; /* Remove bottom border for second last row */
}
th {
    background-color: #f2f2f2;
    text-align: left;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    font-weight: 450;
    border-top: 1px solid #ddd; /* Ensure top border is included */
}
.text-end {
    text-align: right;
}
.font-size-16 {
    font-size: 16px;
}
.font-size-15 {
    font-size: 15px;
}
.fw-semibold {
    font-weight: 600;
}
.border-0 {
    border: none;
}
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
}
.flex-item {
    width: 48%;
    line-height: 1;
}
.total-section {
    margin-top: 20px;
}
.bold {
    font-weight: bold;
}
